import { createSlice } from '@reduxjs/toolkit'

const linkageConfig = [
    'pcard-details',
    'priority-registration',
    'priority-registration-success',
    'priority-registration-failed',
    'priority-status',
    'payment',
    'rewards-success',
    'my-redemptions',
    'opt-out',
    'opt-out-success',
    'opt-out-failed',
    'communication-preference',
    'faq',
    /rewards/,
    'reward-detail',
    'my-redemption',
    'terms-conditions',
    'intro-video',
    'payment-result',
    'rewards-failed',
    'page-no-response',
    '*',
]

export const slice = createSlice({
    name: 'appConfigs',
    initialState: {
        models: {},
        newRoutes: [],
        configFunction: [],
        functionWhitelisting: linkageConfig,
        newRoutesWhitelisting: linkageConfig, // string and regular
    },
    reducers: {
        setPageConfig: (state, action) => {
            state.models = action.payload
        },
        setAppConfigsNewRoutes: (state, action) => {
            state.newRoutes = action.payload
        },
        setAppConfigFunction: (state, action) => {
            state.configFunction = action.payload
        },
    },
})

export const { setAppConfigsNewRoutes, setAppConfigFunction, setPageConfig } =
    slice.actions
export default slice.reducer
