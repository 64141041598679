import styled from 'styled-components'
import ErrorIcon from 'assets/icons/ErrorIcon.png'
import { device } from 'style/devices'
import bridge from 'utils/mobileJSBridge.js'
import { useTranslation } from 'react-i18next'

const unfoundPage = () => {
    const { t } = useTranslation()

    return (
        <NoWapper>
            <NoIcon src={ErrorIcon} />
            <NoText>{t('Unfound.title')}</NoText>
            <NoAvailableButton
                onClick={() => {
                    bridge.closeWebView()
                }}
            >
                {t('Unfound.button')}
            </NoAvailableButton>
        </NoWapper>
    )
}
const NoWapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`
const NoIcon = styled.img`
    width: 60rem;
    @media ${device.tablet} {
        width: 60px;
    }
`
const NoText = styled.div`
    line-height: 21rem;
    font-size: 16rem;
    padding-top: 30rem;
    color: #000;
    text-align: center;
    white-space: pre-line;
    @media ${device.tablet} {
        width: 200px;
        line-height: 21px;
        font-size: 16px;
        padding-top: 30px;
    }
`
const NoAvailableButton = styled.div`
    width: 351rem;
    height: 40rem;
    line-height: 40rem;
    background-color: #003c84;
    color: #fff;
    text-align: center;
    font-size: 16rem;
    border-radius: 40rem;
    margin-top: 173rem;
    @media ${device.tablet} {
        width: 351px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        border-radius: 40px;
        margin-top: 173px;
    }
`
export default unfoundPage
