import React, { Suspense, lazy } from 'react'
import ContactUsPage from 'pages/ContactUsPage'
import PriorityRegistrationSuccess from 'pages/PriorityRegistrationSuccess'
import PriorityRegistrationFailed from 'pages/PriorityRegistrationFailed'
import AboutPage from 'pages/AboutPage'
import FaqPage from 'pages/FaqPage'
import PriorityCardDetailsPage from 'pages/PriorityCardDetailsPage'
import OptOutPage from 'pages/OptOutPage'
import OptOutSuccess from 'pages/OptOutSuccess'
import OptOutFailed from 'pages/OptOutFailed'
import TierBenefitPage from 'pages/TierBenefitPage'
import Loading from 'components/Loading/index'
import CommunicationPreferencePage from 'pages/CommunicationPreferencePage'
import TermsConditions from 'pages/TermsConditions'
import PaymentResult from 'pages/PaymentResult'
import RewardsFailed from 'pages/RewardsFailedPage'
import UnavailablePage from 'pages/UnavailablePage'
import PageNoResponse from 'pages/RewardsPage/PageNoResponse'
import UnfoundPage from 'pages/UnfoundPage'
const PriorityRegistrationPage = lazy(
    () => import('pages/PriorityRegistrationPage')
)
const PaymentPage = lazy(() => import('pages/PaymentPage'))
const PriorityStatusPage = lazy(() => import('pages/PriorityStatusPage'))
const RewardsMainPage = lazy(() => import('pages/RewardsPage/RewardsMainPage'))
const RewardsDetail = lazy(() => import('pages/RewardsPage/RewardsDetail'))
const MyRedemptionDetail = lazy(
    () => import('pages/RewardsPage/MyRedemptionDetail')
)

const MyRedemptionsPage = lazy(() => import('pages/MyRedemptionsPage'))
const RewardsSuccessPage = lazy(() => import('pages/RewardsSuccessPage'))
const IntroVideoPage = lazy(() => import('pages/IntroVideoPage'))
const withLoadingComponent = (comp) => (
    <Suspense fallback={<Loading />}>{comp}</Suspense>
)

export const routes = [
    {
        path: '/contact-us',
        element: <ContactUsPage />,
    },
    {
        path: '/pcard-details',
        element: <PriorityCardDetailsPage />,
    },

    {
        path: '/priority-registration',
        element: withLoadingComponent(<PriorityRegistrationPage />),
    },
    {
        path: '/terms-conditions',
        element: <TermsConditions />,
    },
    {
        path: '/priority-registration-success',
        element: <PriorityRegistrationSuccess />,
    },
    {
        path: '/priority-registration-failed',
        element: <PriorityRegistrationFailed />,
    },
    {
        path: '/priority-status',
        element: withLoadingComponent(<PriorityStatusPage />),
    },
    {
        path: '/payment',
        element: withLoadingComponent(<PaymentPage />),
    },
    {
        path: '/rewards-success',
        element: withLoadingComponent(<RewardsSuccessPage />),
    },
    {
        path: '/my-redemptions',
        element: withLoadingComponent(<MyRedemptionsPage />),
    },
    {
        path: '/opt-out',
        element: <OptOutPage />,
    },
    {
        path: '/opt-out-success',
        element: <OptOutSuccess />,
    },
    {
        path: '/opt-out-failed',
        element: <OptOutFailed />,
    },
    {
        path: '/communication-preference',
        element: <CommunicationPreferencePage />,
    },
    {
        path: '/faq',
        element: <FaqPage />,
    },
    {
        path: '/tier-benefit',
        element: <TierBenefitPage />,
    },
    {
        path: '/about-programme',
        element: <AboutPage />,
    },
    {
        path: '/rewards',
        element: withLoadingComponent(<RewardsMainPage />),
    },
    {
        path: '/reward-detail',
        element: withLoadingComponent(<RewardsDetail />),
    },
    {
        path: '/my-redemption',
        element: withLoadingComponent(<MyRedemptionDetail />),
    },
    {
        path: '/intro-video',
        element: withLoadingComponent(<IntroVideoPage />),
    },
    {
        path: '/payment-result',
        element: withLoadingComponent(<PaymentResult />),
    },
    {
        path: '/rewards-failed',
        element: withLoadingComponent(<RewardsFailed />),
    },
    {
        path: '/unavailable',
        element: withLoadingComponent(<UnavailablePage />),
    },
    {
        path: '/page-no-response',
        element: <PageNoResponse />,
    },
    {
        path: '/*',
        element: withLoadingComponent(<UnfoundPage />),
    },
]
