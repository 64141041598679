import React from 'react'
import ReactDOM from 'react-dom/client'
import axios from 'axios'
import i18next from 'i18next'
import { I18nextProvider } from 'react-i18next'
import Vconsole from 'vconsole'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { LoadingProvider } from './components/Loading/provider'
import { appConfigs } from 'apis/mvp/actions'
import en from 'locates/en.json'
import tc from 'locates/tc.json'
import sc from 'locates/sc.json'
import Loading from 'components/Loading/index'
import PageUnavailable from 'components/PageUnavailable'

const apiToken = localStorage.getItem('api-token')
const isDebugMode = localStorage.getItem('debug')
localStorage.clear()
if (apiToken) localStorage.setItem('api-token', apiToken)
if (isDebugMode) localStorage.setItem('debug', isDebugMode)

if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
    window.vconsole = new Vconsole()
    window.vconsole.hideSwitch()
}
window.setDebug = (is = true) => {
    localStorage.setItem('debug', is)
    location.reload()
}
if (
    localStorage.getItem('debug') === 'true' ||
    process.env.REACT_APP_ENVIRONMENT === 'development'
) {
    // window.console.log = function (data) {}
} else {
    window.console.log = function (data) {}
}

console.log('App init starts:', new Date().toISOString())
const urlParams = new URLSearchParams(window.location.search)
const lang = urlParams.get('lang')
const loadCSS = (href) => {
    return new Promise((resolve, reject) => {
        const link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = href
        link.type = 'text/css'
        link.onload = () => resolve()
        link.onerror = () => reject(new Error(`Failed to load CSS: ${href}`))
        document.getElementsByTagName('head')[0].appendChild(link)
    })
}

let url =
    'https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap'
let fontFamily = 'Noto Sans'
if (lang === 'zh-HK') {
    url =
        'https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100..900&display=swap'
    fontFamily = 'Noto Sans TC'
}

if (process.env.REACT_APP_PREVIEW_VERSION !== 'true') {
    window.onload = function () {
        loadCSS(url)
            .then(() => {
                document.body.style.setProperty('font-family', fontFamily)
                console.log(
                    '[File index.js][Fn loadCSS] CSS loaded successfully! font-family:',
                    fontFamily
                )
            })
            .catch((error) => {
                console.log(
                    '[File index.js][Fn loadCSS]CSS loaded failed: ',
                    error.message
                )
            })
    }
}

const root = ReactDOM.createRoot(document.getElementById('root'))
// locates Configuration and Middle Office Configuration page names must correspond.
const textConfig = (en, tc, sc, models = {}) => {
    for (const page in models) {
        const pageData = models[page] || {}
        for (const j in pageData.wording) {
            if (!en[page]) {
                en[page] = {}
                tc[page] = {}
                sc[page] = {}
            }
            en[page][j] = pageData.wording[j]['en-US']
            tc[page][j] = pageData.wording[j]['zh-HK']
            sc[page][j] = pageData.wording[j].sc
        }
    }
}

root.render(<Loading resources={{ 'en-US': en, 'zh-HK': tc, sc }} />)

const init = (data) => {
    textConfig(en, tc, sc, data?.models)
    i18next.init({
        fallbackLng: 'en-US',
        resources: {
            'en-US': {
                translation: en,
            },
            'zh-HK': {
                translation: tc,
            },
            sc: {
                translation: sc,
            },
        },
        lng: lang || 'en-US',
    })

    root.render(
        <React.StrictMode>
            <I18nextProvider i18n={i18next}>
                <LoadingProvider>
                    <App appConfigs={data} />
                </LoadingProvider>
            </I18nextProvider>
        </React.StrictMode>
    )
}

if (process.env.REACT_APP_GET_LOCAL_CONFIG === 'true') {
    appConfigs().then(({ data }) => {
        console.log('appconfig data', data)
        init(data)
    })
} else {
    const isPreview = process.env.REACT_APP_PREVIEW_VERSION === 'true'
    const CDN_PATH = isPreview
        ? process.env.REACT_APP_CDN_PREVIEW_PATH
        : process.env.REACT_APP_CDN_PATH

    if (isPreview) console.log('Start Preview Mode! get preview web configs.')
    axios
        .get(
            `https://${CDN_PATH}/-/Media/sites/LoyaltyEB/media/app/webviewConfigs?t=${Date.parse(new Date())}`
        )
        .then((result) => {
            init(result.data)
        })
        .catch((error) => {
            console.log(error)
            root.render(
                <div style={{ height: '100vh' }}>
                    <PageUnavailable />
                </div>
            )
        })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
